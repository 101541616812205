import { IConfig } from '@/src/app/core/interfaces';
import { AppConfigService } from '@/src/app/core/services';
import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * MainMenuComponent
 *
 * @export
 * @class MainMenuComponent
 * @typedef {MainMenuComponent}
 */
@Component({
  selector: 'aup-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  standalone: false,
})
export class MainMenuComponent {
  /**
   * Flag describe if menu is expanded
   *
   * @type {boolean}
   */
  @Input() isMenuExpanded: boolean;
  /**
   * Flag describe if it is mobile browser
   *
   * @type {boolean}
   */
  @Input() isMobile: boolean;
  /**
   * Emit event on menu position click
   *
   * @type {EventEmitter<void>}
   */
  @Output() closeDrawerIfMobile: EventEmitter<void> = new EventEmitter();

  appConfig: IConfig;

  constructor(private appConfigService: AppConfigService) {
    this.appConfig = this.appConfigService.getConfig();
  }

  /**
   * Handle menu position click
   */
  onCloseDrawerIfMobile() {
    this.closeDrawerIfMobile.emit();
  }
}
