import { Injectable, Injector } from '@angular/core';

import { InvoicesService } from '@services';
import { SentInvoicesActions } from './sent-invoices.actions';

import { EApiCallStateKey } from '../api-call-state/api-call-state.enum';
import { SentInvoiceListItem } from '@models';

import { SuperApiCallStateEffect } from '../effects/super-api-call-state.effect';
import { IApiRequest } from '@interfaces';
import { iif, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SentInvoicesEffect extends SuperApiCallStateEffect<SentInvoiceListItem[]> {
  apiCallStateKey = EApiCallStateKey.SENT_INVOICES;
  featureActions = SentInvoicesActions;

  constructor(
    private service: InvoicesService,
    injector: Injector
  ) {
    super(injector);
  }

  callApi(requestParams: IApiRequest) {
    // NOTE: if there are no filters we return empty array,
    // this is done to avoid calling api with empty filters and getting all data
    // return iif(
    //   () => requestParams.filters?.length > 0,
    //   this.service.listSentInvoices(requestParams),
    //   of({ data: [] })
    // );
    this.store$.dispatch(this.featureActions.lastApiRequest({ apiRequest: requestParams }));
    return this.service.listSentInvoices(requestParams);
  }
}
