import { Injectable, Injector } from '@angular/core';

import { FelService } from '@services';
import { FelActions } from './fel.actions';

import { EApiCallStateKey } from '../api-call-state/api-call-state.enum';
import { Fel } from '@models';

import { SuperApiCallStateEffect } from '../effects/super-api-call-state.effect';
import { IApiRequest } from '@interfaces';
import { iif, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FelEffect extends SuperApiCallStateEffect<Fel[]> {
  apiCallStateKey = EApiCallStateKey.FEL;
  featureActions = FelActions;

  constructor(
    private service: FelService,
    injector: Injector
  ) {
    super(injector);
  }

  callApi(requestParams: IApiRequest) {
    // NOTE: if there are no filters we return empty array,
    // this is done to avoid calling api with empty filters and getting all data
    return iif(
      () => requestParams.filters?.length > 0,
      this.service.get<Fel>(requestParams),
      of({ data: [] })
    );
  }
}
