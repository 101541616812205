import { Component } from '@angular/core';

/**
 * Not Found Component
 * @export
 * @class NotFoundComponent
 * @typedef {NotFoundComponent}
 */
@Component({
  selector: 'aup-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  standalone: false,
})
export class NotFoundComponent {}
