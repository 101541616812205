import { Injectable, Injector } from '@angular/core';

import { InvoicesService } from '@services';
import { ReceivedInvoicesActions } from './received-invoices.actions';

import { EApiCallStateKey } from '../api-call-state/api-call-state.enum';
import { ReceivedInvoiceListItem } from '@models';

import { SuperApiCallStateEffect } from '../effects/super-api-call-state.effect';
import { IApiRequest } from '@interfaces';

@Injectable({ providedIn: 'root' })
export class ReceivedInvoicesEffect extends SuperApiCallStateEffect<ReceivedInvoiceListItem[]> {
  apiCallStateKey = EApiCallStateKey.RECEIVED_INVOICES;
  featureActions = ReceivedInvoicesActions;

  constructor(
    private service: InvoicesService,
    injector: Injector
  ) {
    super(injector);
  }

  callApi(requestParams: IApiRequest) {
    // NOTE: if there are no filters we return empty array,
    // this is done to avoid calling api with empty filters and getting all data
    // return iif(
    //   () => requestParams.filters?.length > 0,
    //   this.service.listReceivedInvoices(requestParams),
    //   of({ data: [] })
    // );
    this.store$.dispatch(this.featureActions.lastApiRequest({ apiRequest: requestParams }));
    return this.service.listReceivedInvoices(requestParams);
  }
}
