<mat-nav-list *ngIf="!isMobile" class="!py-0">
  <a
    mat-list-item
    class="aup-menu-button !border-solid !border-[2px] !border-primary-interactive text-center !text-primary-interactive"
    [routerLink]="['/']"
  >
    {{ 'SETTINGS_MENU.BACK' | transloco }}
  </a>
  <a
    mat-list-item
    routerLinkActive="aup-menu-item-active"
    [routerLink]="['settings', 'general']"
    class="!h-12 lg:!h-8"
    (click)="closeDrawerIfMobile.emit()"
    data-cy="settings-general-link"
  >
    <mat-icon
      matListItemIcon
      class="!mr-0"
      svgIcon="settings-general"
      [title]="'SETTINGS_MENU.GENERAL' | transloco"
    ></mat-icon>
    <div matListItemTitle class="!ml-3" *ngIf="isMenuExpanded">{{ 'SETTINGS_MENU.GENERAL' | transloco }}</div>
  </a>
</mat-nav-list>

<div *ngIf="isMobile">
  <mat-divider></mat-divider>
  <mat-accordion class="accordion-menu mat-elevation-z" displayMode="flat">
    <mat-expansion-panel displayMode="flat" class="mat-elevation-z">
      <mat-expansion-panel-header>
        <div class="flex items-center">
          <mat-icon svgIcon="settings"></mat-icon>
          <span matListItemTitle class="ml-3">{{ 'SETTINGS_MENU.SETTINGS' | transloco }}</span>
        </div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-nav-list class="!py-0">
          <a
            mat-list-item
            routerLinkActive="aup-menu-item-active"
            [routerLink]="['settings', 'general']"
            class="!h-12 lg:!h-8"
            (click)="closeDrawerIfMobile.emit()"
            data-cy="settings-general-link"
          >
            <div matListItemTitle class="!ml-3" *ngIf="isMenuExpanded">
              {{ 'SETTINGS_MENU.GENERAL_SHORT' | transloco }}
            </div>
          </a>
        </mat-nav-list>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>
