<mat-nav-list class="!py-0">
  <a
    mat-list-item
    routerLinkActive="aup-menu-item-active"
    [routerLink]="['dashboard']"
    (click)="onCloseDrawerIfMobile()"
    class="!h-12 lg:!h-8"
  >
    <mat-icon matListItemIcon svgIcon="home" class="!mr-0" [title]="'SIDENAV.HOME' | transloco"></mat-icon>
    <div matListItemTitle class="!ml-3" *ngIf="isMenuExpanded">{{ 'SIDENAV.HOME' | transloco }}</div>
  </a>
  <ng-container *ngIf="appConfig?.felModuleEnabled">
    <div mat-subheader *ngIf="isMenuExpanded" class="!text-sm !font-normal font-lato text-black">
      {{ 'SIDENAV.TITLE.ELECTRONIC_INVOICING' | transloco }}
    </div>
    <a
      mat-list-item
      routerLinkActive="aup-menu-item-active"
      [routerLink]="['delegations', 'fel']"
      class="!h-12 lg:!h-8"
      (click)="onCloseDrawerIfMobile()"
      data-cy="fel-link"
    >
      <mat-icon
        matListItemIcon
        class="!mr-0"
        svgIcon="delegations"
        [title]="'SIDENAV.FEL_DELEGATION' | transloco"
      ></mat-icon>
      <div matListItemTitle class="!ml-3" *ngIf="isMenuExpanded">{{ 'SIDENAV.DELEGATIONS' | transloco }}</div>
    </a>
    <a
      mat-list-item
      class="!h-12 lg:!h-8"
      routerLinkActive="aup-menu-item-active"
      [routerLink]="['invoices']"
      (click)="onCloseDrawerIfMobile()"
      data-cy="invoices-link"
    >
      <mat-icon
        matListItemIcon
        class="!mr-0"
        svgIcon="search"
        [title]="'SIDENAV.SEARCH_INVOICE' | transloco"
      ></mat-icon>
      <div matListItemTitle class="!ml-3" *ngIf="isMenuExpanded">
        {{ 'SIDENAV.SEARCH_INVOICE' | transloco }}
      </div>
    </a>
  </ng-container>
  <ng-container *ngIf="appConfig?.pecModuleEnabled">
    <div mat-subheader *ngIf="isMenuExpanded" class="!text-sm !font-normal font-lato text-black">
      {{ 'SIDENAV.TITLE.PEC' | transloco }}
    </div>
    <a
      mat-list-item
      routerLinkActive="aup-menu-item-active"
      [routerLink]="['delegations', 'pec']"
      class="!h-12 lg:!h-8"
      (click)="onCloseDrawerIfMobile()"
      data-cy="pec-link"
    >
      <mat-icon
        matListItemIcon
        class="!mr-0"
        svgIcon="delegations"
        [title]="'SIDENAV.PEC_DELEGATION' | transloco"
      ></mat-icon>
      <div matListItemTitle class="!ml-3" *ngIf="isMenuExpanded">{{ 'SIDENAV.DELEGATIONS' | transloco }}</div>
    </a>
  </ng-container>
</mat-nav-list>
<ng-container *ngIf="isMobile">
  <mat-divider></mat-divider>
  <aup-settings-menu
    (closeDrawerIfMobile)="onCloseDrawerIfMobile()"
    [isMenuExpanded]="isMenuExpanded"
    [isMobile]="isMobile"
    class="aup-menu"
  ></aup-settings-menu>
</ng-container>
