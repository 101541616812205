import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class CustomIconsService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer
  ) {}

  init() {
    this.matIconRegistry.addSvgIcon(
      'accessibility',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/accessibility.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'agenda',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/agenda.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'assistance',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/assistance.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'close',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/close.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'delegations',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/delegations.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'exit',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/exit.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'expand',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/expand.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'guide',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/guide.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'home',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/home.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'menu-mobile',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/menu-mobile.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'more',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/more.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'news',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/news.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'search',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/search.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'security-lock',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/security-lock.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'user',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/user.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'verified',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/verified.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'success',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/success.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'info',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/info.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'warning',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/warning.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'error',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/error.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'arrow-left',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/arrow-left.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'not-found',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/not-found.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'invoice',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/invoice.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'settings',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/settings.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'settings-general',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/settings-general.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'arrow-right',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/arrow-right.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'chevron-left',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/chevron-left.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'mobile',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/mobile.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'queue',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/queue.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'minus',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/minus.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'close_white',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/close_white.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'search-lens',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/search-lens.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'edit-columns',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/edit-columns.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'drag-and-drop',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/drag-and-drop.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'quick-sort',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/quick-sort.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'quick-sort-direction',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/quick-sort-direction.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'advanced-filters',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/advanced-filters.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'add',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/add.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'delete',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/delete.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'unmanaged',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/aruba/unmanaged.svg')
    );
  }
}
