import { CUSTOM_ELEMENTS_SCHEMA, NgModule, inject, provideAppInitializer } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';

import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { AppConfigService } from './core/services/app-config.service';
import { DateTimeProvider, OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { StateModule } from './core/state/state.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomIconsService } from './core/services/custom-icons.service';
import localeIt from '@angular/common/locales/it';
import { PasswordExpirationService } from './core/services/password-expiration.service';
import { CustomDateTimeProvider } from './core/services/custom-date-provider.service';
import { lastValueFrom } from 'rxjs';
import { EXCLUDE_OAUTH_INTERCEPTOR_HEADER } from './core/interceptors/oauth-interceptor.service';

registerLocaleData(localeIt);

function authAppInitializer(appConfig: AppConfigService, oauthService: OAuthService, httpClient: HttpClient) {
  return async () => {
    await appConfig.loadConfig();
    const config = appConfig.getConfig();

    let timeDifferenceInSeconds = 0;
    if (config.dateApiUrl) {
      const date$ = httpClient.get(config.dateApiUrl, {
        headers: {
          [EXCLUDE_OAUTH_INTERCEPTOR_HEADER]: 'skip',
        },
      });
      const serverDate: any = await lastValueFrom(date$);
      const date = new Date();
      const serverDateInSeconds = Math.floor(serverDate.data);
      const clientDateInSeconds = Math.floor(date.getTime() / 1000);
      timeDifferenceInSeconds = serverDateInSeconds - clientDateInSeconds;
    }

    localStorage.setItem('time_difference_in_seconds', timeDifferenceInSeconds.toString());

    oauthService.configure({
      issuer: config.keycloak.issuerUrl,
      clientId: config.keycloak.clientId,
      scope: config.keycloak.clientScope,
      redirectUri: config.baseUri + '/auth-redirect',
      postLogoutRedirectUri: config.baseUri,
      responseType: 'code',
      showDebugInformation: true,
      clockSkewInSec: 60,
      timeoutFactor: 0.75,
      sessionChecksEnabled: false,
      clearHashAfterLogin: false,
    });
  };
}

function appInitialization(
  customIconService: CustomIconsService,
  passwordExpirationService: PasswordExpirationService
) {
  return () => {
    customIconService.init();
    passwordExpirationService.init();
  };
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    CoreModule,
    OAuthModule.forRoot(),
    StateModule,
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: OAuthStorage, useFactory: oAuthStorageFactory },
    provideAppInitializer(() => {
      const initializerFn = authAppInitializer(
        inject(AppConfigService),
        inject(OAuthService),
        inject(HttpClient)
      );
      return initializerFn();
    }),
    provideAppInitializer(() => {
      const initializerFn = appInitialization(inject(CustomIconsService), inject(PasswordExpirationService));
      return initializerFn();
    }),
    { provide: DateTimeProvider, useClass: CustomDateTimeProvider },
    { provide: LOCALE_ID, useValue: 'it-IT' },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}

export function oAuthStorageFactory(): OAuthStorage {
  return localStorage;
}
