import { Component, OnInit } from '@angular/core';
import { AuthService, DelegationPecMailService } from '../../services';
import { Router } from '@angular/router';

/**
 * SignInComponent
 *
 * @export
 * @class SignInComponent
 * @typedef {SignInComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'ltl-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  standalone: false,
})
export class SignInComponent implements OnInit {
  /**
   * Creates an instance of SignInComponent.
   *
   * @constructor
   * @param {AuthService} authService
   * @param {Router} router
   * @param {DelegationPecMailService} delegationPecMailService
   */
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly delegationPecMailService: DelegationPecMailService
  ) {}

  ngOnInit(): void {
    if (this.authService.accessToken && this.authService.hasValidToken()) {
      this.delegationPecMailService.recalculateEmail();
      this.router.navigate(['/dashboard']);
    } else {
      this.authService.login();
    }
  }
}
