import { Component } from '@angular/core';
import { EAppNotificationType } from '@enums';
import { AuthService } from '../../services';

/**
 * User Suspended Component
 * @export
 * @class UserSuspendedComponent
 * @typedef {UserSuspendedComponent}
 */
@Component({
  selector: 'aup-user-suspended',
  templateUrl: './user-suspended.component.html',
  styleUrls: ['./user-suspended.component.scss'],
  standalone: false,
})
export class UserSuspendedComponent {
  EAppNotificationType = EAppNotificationType;

  constructor(public authService: AuthService) {}
}
