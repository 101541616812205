import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'aup-redirect-login',
  templateUrl: './redirect-login.component.html',
  styleUrls: ['./redirect-login.component.scss'],
  standalone: false,
})
export class RedirectLoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private oauthService: OAuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.authService.accessToken && this.authService.hasValidToken()) {
      this.router.navigate(['/']);
    } else {
      this.oauthService.customQueryParams = this.activatedRoute.snapshot.queryParams;
      this.authService.login();
    }
  }
}
