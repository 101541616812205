import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';
import { throwIfAlreadyLoaded } from './module-config';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LayoutComponent } from './components/layout/layout.component';
import { FooterComponent } from './components/footer/footer.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { OAuthInterceptor } from './interceptors/oauth-interceptor.service';
import { ApiErrorsInterceptor } from './interceptors/api-errors-interceptor.service';
import { RedirectLoginComponent } from './components/redirect-login/redirect-login.component';
import { UserSuspendedComponent } from './components/user-suspended/user-suspended.component';
import { SignOutComponent } from './components/sign-out/sign-out.component';
import { MainMenuComponent } from './components/layout/menus/main-menu/main-menu.component';
import { SettingsMenuComponent } from './components/layout/menus/settings-menu/settings-menu.component';

const EXPORTS_COMPONENTS = [
  NotFoundComponent,
  LayoutComponent,
  SignInComponent,
  RedirectLoginComponent,
  UserSuspendedComponent,
  SignOutComponent,
];
const COMPONENTS = [MainMenuComponent, SettingsMenuComponent, FooterComponent];

@NgModule({
  imports: [RouterModule, SharedModule.forRoot()],
  exports: [...EXPORTS_COMPONENTS],
  declarations: [...EXPORTS_COMPONENTS, ...COMPONENTS],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiErrorsInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
